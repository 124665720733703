import {
  EMapIceLayer,
  EMapLayer,
  EMapView,
  L,
  TLayerInfo,
  TLayerValues
} from 'types';

export enum EColorDescriptor {
  COUNTRIES = 'COUNTRIES',
  VESSEL_TYPES = 'VESSEL_TYPES'
}

export type TMapOptions = {
  center: L.LatLng;
  colorDescriptor: EColorDescriptor;
  currentLayer: EMapLayer;
  iceLayers: EMapIceLayer[];
  isPolyTrack: boolean;
  layerDateLabel: string;
  layerInfo: TLayerInfo;
  layerValuesMap: Record<EMapLayer, TLayerValues>;
  primaryView: EMapView;
  zoom: number;
};

import merge from 'lodash.merge';

import { basicInitialState } from '../../_utils/basicInitialState';
import { DEFAULT_LAYER_VALUES } from 'other/map/layerValues';
import { SettingsService } from 'services/settings';

import { EColorDescriptor } from 'pages/Map/types/types';
import { EMapLayer, EMapView, ETrackMode } from 'types';
import { TMapOptionsState } from './mapOptionsModel';

/**/
const settings = SettingsService.getMapSettings() as TMapOptionsState;

/**
 *
 */
export const mapOptionsInitialState: TMapOptionsState = {
  ...basicInitialState,
  center: settings.center || null,
  zoom: settings.zoom || null,

  colorDescriptor: EColorDescriptor.COUNTRIES,
  fishingActivitySpeed: settings.fishingActivitySpeed || [1, 5.5],
  iceLayers: settings.iceLayers || [],
  isPredictionOn: settings.isPredictionOn,
  layer: settings.layer || EMapLayer.EEZ,
  layerDateLabel: null,
  layerInfo: {} as any,
  layerValuesMap: merge(
    {},
    DEFAULT_LAYER_VALUES,
    settings.layerValuesMap || ({} as any)
  ),
  // tertiary operator is applied for the transitional period.
  // todo: tertiary operator should be removed in May 2024
  primaryView: settings.primaryView || EMapView.ATLANTIC,
  trackMode:
    typeof settings.trackMode === 'boolean' || settings.trackMode === void 0
      ? ETrackMode.DEFAULT
      : settings.trackMode
};
